<template>
  <section>
    <call-hacs-form ref="CallHacsForm" :form="form" />

    <call-hacs-time-lines
      ref="CallHacsTimeLines"
      :access_token="form.access_token"
    />
  </section>
</template>
<script>
export default {
  data() {
    return {
      form: {
        access_token: "",
        url_repository: "",
      },
    }
  },
  components: {
    CallHacsForm: () => import("./components/index/CallHacsForm.vue"),
    CallHacsTimeLines: () => import("./components/index/CallHacsTimeLines.vue"),
  },
  created() {
    this.fetchAccessToken()
  },
  methods: {
    async fetchAccessToken() {
      try {
        const resp = await this.api.get(`/api/users/accessToken`)
        this.form.access_token = resp.access_token
        this.form.url_repository = resp.url_repository
      } catch (error) {
        console.error(error)
        return ""
      }
    },
  },
}
</script>
